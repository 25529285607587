import Validator from "validator";

import isEmpty from "./is-empty";

export const loginValidation = (data) => {
  let errors = {};

  data.email = data.email.trim() ? data.email : "";
  data.password = data.password.trim() ? data.password : "";

  if (!Validator.isEmail(data.email)) {
    errors.email = "Email is invalid";
  }

  if (Validator.isEmpty(data.email)) {
    errors.email = "Email field is required";
  }

  if (Validator.isEmpty(data.password)) {
    errors.password = "Password field is required";
  }

  return {
    errors,
    isValid: Object.keys(errors).length === 0,
  };
};

export const forgotPasswordValidation = (data) => {
  let errors = {};

  data.email = data.email.trim() ? data.email : "";

  if (!Validator.isEmail(data.email)) {
    errors.email = "Email is invalid";
  }

  if (Validator.isEmpty(data.email)) {
    errors.email = "Email field is required";
  }

  return {
    errors,
    isValid: Object.keys(errors).length === 0,
  };
};

export const verificationPageValidation = (data) => {
  let errors = {};

  data.email = data.email.trim() ? data.email : "";

  if (!Validator.isEmail(data.email)) {
    errors.email = "Email is invalid";
  }

  if (Validator.isEmpty(data.email)) {
    errors.email = "Email field is required";
  }

  return {
    errors,
    isValid: Object.keys(errors).length === 0,
  };
};

export const changePasswordValidation = (data) => {
  let errors = {};
  data.email = data.email.trim() ? data.email : "";
  data.password = data.password.trim() ? data.password : "";
  data.confirm_password = data.confirm_password.trim()
    ? data.confirm_password
    : "";

  if (!Validator.isEmail(data.email)) {
    errors.email = "Email is invalid";
  }
  if (Validator.isEmpty(data.password)) {
    errors.password = "New password field is required";
  }
  if (Validator.isEmpty(data.confirm_password)) {
    errors.confirm_password = "Confirm password field is required";
  }

  if (!Validator.equals(data.password, data.confirm_password)) {
    errors.confirm_password = "Passwords must match";
  }

  return {
    errors,
    isValid: Object.keys(errors).length === 0,
  };
};

export const changePasswordValidationadmin = (data) => {
  let errors = {};
  data.password = data.password.trim() ? data.password : "";
  data.current_password = data.current_password.trim()
    ? data.current_password
    : "";
  data.confirm_password = data.confirm_password.trim()
    ? data.confirm_password
    : "";

  if (Validator.isEmpty(data.current_password)) {
    errors.current_password = "New password field is required";
  }
  if (Validator.isEmpty(data.password)) {
    errors.password = "New password field is required";
  }
  if (Validator.isEmpty(data.confirm_password)) {
    errors.confirm_password = "Confirm password field is required";
  }

  if (!Validator.equals(data.password, data.confirm_password)) {
    errors.confirm_password = "Passwords must match";
  }

  return {
    errors,
    isValid: Object.keys(errors).length === 0,
  };
};

export const formValidation = (data) => {
  let errors = {};
  data.name = data.name.trim() ? data.name : "";

  if (Validator.isEmpty(data.name)) {
    errors.name = "Name field is required";
  }
  return {
    errors,
    isValid: Object.keys(errors).length === 0,
  };
};

export const subGymValidation = (data) => {
  let errors = {};

  data.email = data.email.trim() ? data.email : "";
  data.password = data.password.trim() ? data.password : "";

  if (!Validator.isEmail(data.email)) {
    errors.email = "Email is invalid";
  }

  if (Validator.isEmpty(data.email)) {
    errors.email = "Email field is required";
  }

  if (Validator.isEmpty(data.password)) {
    errors.password = "Password field is required";
  }

  return {
    errors,
    isValid: Object.keys(errors).length === 0,
  };
};

export const gymvalidation = (data) => {
  let errors = {};

  data.email = data.email.trim() ? data.email : "";

  data.name = data.name.trim() ? data.name : "";
  data.name_ar = data.name_ar.trim() ? data.name_ar : "";
  data.gym_mobile = data.gym_mobile.trim() ? data.gym_mobile : "";
  data.description_ar = data.description_ar.trim() ? data.description_ar : "";
  data.description = data.description.trim() ? data.description : "";
  data.photos = data.photos ? data.photos : [];
  data.category = data.category ? data.category : [];
  data.gender_id = data.gender_id.trim() ? data.gender_id : "";

  if (!Validator.isEmail(data.email)) {
    errors.email = "Email is invalid";
  }

  if (Validator.isEmpty(data.email)) {
    errors.email = "Email field is required";
  }
  if (Validator.isEmpty(data.name)) {
    errors.name = "Name field is required";
  }
  if (Validator.isEmpty(data.name_ar)) {
    errors.name_ar = "Name arabic field is required";
  }
  if (Validator.isEmpty(data.gym_mobile)) {
    errors.gym_mobile = "Mobile field is required";
  }
  if (Validator.isEmpty(data.gym_mobile)) {
    errors.isMobile = true;
    //errors.mobile = 'Phone Number  field is required';
  } else if (!Validator.isNumeric(data.gym_mobile)) {
    errors.isMobile = true;
    errors.gym_mobile = "MobileNumberOnly";
  } else if (!Validator.isLength(data.gym_mobile, { min: 8, max: 8 })) {
    errors.isMobile = true;
    errors.gym_mobile = "Mobile number is not valid";
  }

  if (Validator.isEmpty(data.description_ar)) {
    errors.description_ar = "Description arabic field is required";
  }

  if (Validator.isEmpty(data.description)) {
    errors.description = "Description  field is required";
  }
  if (!isEmpty(data.photos.length === 0)) {
    errors.photos = "Photos is required";
  }
  if (!isEmpty(data.category.length === 0)) {
    errors.category = "Category is required";
  }
  if (Validator.isEmpty(data.gender_id)) {
    errors.gender_id = "Gender field is required";
  }

  return {
    errors,
    isValid: Object.keys(errors).length === 0,
  };
};

export const editGymvalidation = (data) => {
  let errors = {};

  data.email = data.email ? data.email : "";

  data.name = data.name.trim() ? data.name : "";
  data.name_ar = data.name_ar.trim() ? data.name_ar : "";
  data.gym_mobile = data.gym_mobile.trim() ? data.gym_mobile : "";
  data.description_ar = data.description_ar.trim() ? data.description_ar : "";
  data.description = data.description.trim() ? data.description : "";
  data.gender_id = data.gender_id ? data.gender_id : 0;
  // data.photos = data.photos ? data.photos : [];
  // data.category = data.category ? data.category : [];

  if (!Validator.isEmail(data.email)) {
    errors.email = "Email is invalid";
  }

  if (Validator.isEmpty(data.email)) {
    errors.email = "Email field is required";
  }
  if (Validator.isEmpty(data.name)) {
    errors.name = "Name field is required";
  }
  if (Validator.isEmpty(data.name_ar)) {
    errors.name_ar = "Name arabic field is required";
  }
  if (Validator.isEmpty(data.gym_mobile)) {
    errors.gym_mobile = "Mobile field is required";
  }
  if (Validator.isEmpty(data.gym_mobile)) {
    errors.isMobile = true;
    //errors.mobile = 'Phone Number  field is required';
  } else if (!Validator.isNumeric(data.gym_mobile)) {
    errors.isMobile = true;
    errors.gym_mobile = "MobileNumberOnly";
  } else if (!Validator.isLength(data.gym_mobile, { min: 8, max: 8 })) {
    errors.isMobile = true;
    errors.gym_mobile = "Mobile number is not valid";
  }

  if (Validator.isEmpty(data.description_ar)) {
    errors.description_ar = "Description arabic field is required";
  }

  if (Validator.isEmpty(data.description)) {
    errors.description = "Description  field is required";
  }
  if (!isEmpty(data.photos) && !isEmpty(data.photos.length === 0)) {
    errors.photos = "Photos is required";
  }
  if (!isEmpty(data.category) && !isEmpty(data.category.length === 0)) {
    errors.category = "Category is required";
  }
  if (!data.gender_id) {
    errors.gender_id = "Gender field is required";
  }

  return {
    errors,
    isValid: Object.keys(errors).length === 0,
  };
};

export const editGymvalidationPopup = (data) => {
  let errors = {};

  //data.email = data.email ? data.email : "";

  data.name = data.name.trim() ? data.name : "";
  data.name_ar = data.name_ar.trim() ? data.name_ar : "";
  data.gym_mobile = data.gym_mobile.trim() ? data.gym_mobile : "";
  data.description_ar = data.description_ar.trim() ? data.description_ar : "";
  data.description = data.description.trim() ? data.description : "";
  data.gender_id = data.gender_id ? data.gender_id : 0;
  // data.photos = data.photos ? data.photos : [];
  // data.category = data.category ? data.category : [];

  if (Validator.isEmpty(data.name)) {
    errors.name = "Name field is required";
  }
  if (Validator.isEmpty(data.name_ar)) {
    errors.name_ar = "Name arabic field is required";
  }
  if (Validator.isEmpty(data.gym_mobile)) {
    errors.gym_mobile = "Mobile field is required";
  }
  if (Validator.isEmpty(data.gym_mobile)) {
    errors.isMobile = true;
    //errors.mobile = 'Phone Number  field is required';
  } else if (!Validator.isNumeric(data.gym_mobile)) {
    errors.isMobile = true;
    errors.gym_mobile = "MobileNumberOnly";
  } else if (!Validator.isLength(data.gym_mobile, { min: 8, max: 8 })) {
    errors.isMobile = true;
    errors.gym_mobile = "Mobile number is not valid";
  }

  if (Validator.isEmpty(data.description_ar)) {
    errors.description_ar = "Description arabic field is required";
  }

  if (Validator.isEmpty(data.description)) {
    errors.description = "Description  field is required";
  }
  if (!isEmpty(data.photos) && !isEmpty(data.photos.length === 0)) {
    errors.photos = "Photos is required";
  }
  if (!isEmpty(data.category) && !isEmpty(data.category.length === 0)) {
    errors.category = "Category is required";
  }
  if (!data.gender_id) {
    errors.gender_id = "Gender field is required";
  }

  return {
    errors,
    isValid: Object.keys(errors).length === 0,
  };
};

export const classvalidation = (data) => {
  let errors = {};

  data.name = data.name.trim() ? data.name : "";
  data.description_ar = data.description_ar.trim() ? data.description_ar : "";
  data.description = data.description.trim() ? data.description : "";
  data.name_ar = data.name_ar.trim() ? data.name_ar : "";
  data.photos = data.photos ? data.photos : [];
  data.gender_id = data.gender_id.trim() ? data.gender_id : "";
  /*  data.start_date = data.start_date ? data.start_date : "";
  data.end_date = data.end_date ? data.end_date : ""; */
  data.category = data.category ? data.category : [];

  if (Validator.isEmpty(data.name)) {
    errors.name = "Name field is required";
  }

  if (Validator.isEmpty(data.description_ar)) {
    errors.description_ar = "Description arabic field is required";
  } else if (!Validator.isLength(data.description_ar, { min: 1, max: 400 })) {
    errors.description_ar = "Maximum characters are 400";
  }

  if (Validator.isEmpty(data.description)) {
    errors.description = "Description  field is required";
  } else if (!Validator.isLength(data.description, { min: 1, max: 400 })) {
    errors.description = "Maximum characters are 400";
  }
  if (Validator.isEmpty(data.name_ar)) {
    errors.name_ar = "Name arabic field is required";
  }
  if (Validator.isEmpty(data.gender_id)) {
    errors.gender_id = "Gender field is required";
  }
  if (!isEmpty(data.photos.length === 0)) {
    errors.photos = "Photos is required";
  }
  /*  if (!data.start_date) {
    errors.start_date = "Start date field is required";
  }
  if (!data.end_date) {
    errors.end_date = "End date field is required";
  }  */
  if (!isEmpty(data.category.length === 0)) {
    errors.category = "Category is required";
  }

  return {
    errors,
    isValid: Object.keys(errors).length === 0,
  };
};

export const editClassvalidation = (data) => {
  let errors = {};

  data.name = data.name.trim() ? data.name : "";
  data.description_ar = data.description_ar.trim() ? data.description_ar : "";
  data.description = data.description.trim() ? data.description : "";
  data.name_ar = data.name_ar.trim() ? data.name_ar : "";
  //  data.photos = data.photos ? data.photos : [];
  data.gender_id = data.gender_id ? data.gender_id : 0;

  //  data.category = data.category ? data.category : [];

  if (Validator.isEmpty(data.name)) {
    errors.name = "Name field is required";
  }

  if (Validator.isEmpty(data.description_ar)) {
    errors.description_ar = "Description arabic field is required";
  } else if (!Validator.isLength(data.description_ar, { min: 1, max: 400 })) {
    errors.description_ar = "Maximum characters are 400";
  }

  if (Validator.isEmpty(data.description)) {
    errors.description = "Description  field is required";
  } else if (!Validator.isLength(data.description, { min: 1, max: 400 })) {
    errors.description = "Maximum characters are 400";
  }
  if (Validator.isEmpty(data.name_ar)) {
    errors.name_ar = "Name arabic field is required";
  }
  if (!data.gender_id) {
    errors.gender_id = "Gender field is required";
  }
  if (!isEmpty(data.photos) && !isEmpty(data.photos.length === 0)) {
    errors.photos = "Photos is required";
  }

  if (!isEmpty(data.category) && !isEmpty(data.category.length === 0)) {
    errors.category = "Category is required";
  }

  return {
    errors,
    isValid: Object.keys(errors).length === 0,
  };
};

export const coachvalidation = (data) => {
  let errors = {};

  data.name = data.name.trim() ? data.name : "";
  data.name_ar = data.name_ar.trim() ? data.name_ar : "";
  data.description = data.description.trim() ? data.description : "";
  data.description_ar = data.description_ar.trim() ? data.description_ar : "";
  data.photos = data.photos ? data.photos : [];
  data.email = data.email.trim() ? data.email : "";
  data.mobile = data.mobile.trim() ? data.mobile : "";
  data.gender_id = data.gender_id.trim() ? data.gender_id : "";

  if (Validator.isEmpty(data.name)) {
    errors.name = "Name field is required";
  }
  if (Validator.isEmpty(data.name_ar)) {
    errors.name_ar = "Name arabic field is required";
  }

  if (Validator.isEmpty(data.description)) {
    errors.description = "Description field is required";
  }
  if (Validator.isEmpty(data.description_ar)) {
    errors.description_ar = "Description arabic field is required";
  }

  if (Validator.isEmpty(data.email)) {
    errors.email = "Email field is required";
  }
  if (!Validator.isEmail(data.email)) {
    errors.email = "Email is invalid";
  }
  if (Validator.isEmpty(data.mobile)) {
    errors.mobile = "Mobile field is required";
  }

  if (Validator.isEmpty(data.mobile)) {
    errors.isMobile = true;
    //errors.mobile = 'Phone Number  field is required';
  } else if (!Validator.isNumeric(data.mobile)) {
    errors.isMobile = true;
    errors.mobile = "MobileNumberOnly";
  } else if (!Validator.isLength(data.mobile, { min: 8, max: 8 })) {
    errors.isMobile = true;
    errors.mobile = "Mobile number is not valid";
  }

  if (!isEmpty(data.photos.length === 0)) {
    errors.photos = "Photos is required";
  }
  if (Validator.isEmpty(data.gender_id)) {
    errors.gender_id = "Gender field is required";
  }

  return {
    errors,
    isValid: Object.keys(errors).length === 0,
  };
};

export const EditCoachvalidation = (data) => {
  let errors = {};

  data.name = data.name.trim() ? data.name : "";
  data.name_ar = data.name_ar.trim() ? data.name_ar : "";
  data.description = data.description.trim() ? data.description : "";
  data.description_ar = data.description_ar.trim() ? data.description_ar : "";
  // data.photos = data.photos ? data.photos : [];
  data.email = data.email.trim() ? data.email : "";
  data.mobile = data.mobile.trim() ? data.mobile : "";
  data.gender_id = data.gender_id ? data.gender_id : "";

  if (Validator.isEmpty(data.name)) {
    errors.name = "Name field is required";
  }
  if (Validator.isEmpty(data.name_ar)) {
    errors.name_ar = "Name arabic field is required";
  }

  if (Validator.isEmpty(data.description)) {
    errors.description = "Description field is required";
  }
  if (Validator.isEmpty(data.description_ar)) {
    errors.description_ar = "Description arabic field is required";
  }

  if (Validator.isEmpty(data.email)) {
    errors.email = "Email field is required";
  }
  if (!Validator.isEmail(data.email)) {
    errors.email = "Email is invalid";
  }
  if (Validator.isEmpty(data.mobile)) {
    errors.mobile = "Mobile field is required";
  }

  if (Validator.isEmpty(data.mobile)) {
    errors.isMobile = true;
    //errors.mobile = 'Phone Number  field is required';
  } else if (!Validator.isNumeric(data.mobile)) {
    errors.isMobile = true;
    errors.mobile = "MobileNumberOnly";
  } else if (!Validator.isLength(data.mobile, { min: 8, max: 8 })) {
    errors.isMobile = true;
    errors.mobile = "Mobile number is not valid";
  }

  if (!isEmpty(data.photos) && !isEmpty(data.photos.length === 0)) {
    errors.photos = "Photos is required";
  }
  if (!data.gender_id) {
    errors.gender_id = "Gender field is required";
  }

  return {
    errors,
    isValid: Object.keys(errors).length === 0,
  };
};

export const categoryvalidation = (data) => {
  let errors = {};

  data.name = data.name.trim() ? data.name : "";
  data.description_ar = data.description_ar.trim() ? data.description_ar : "";
  data.description = data.description.trim() ? data.description : "";
  data.name_ar = data.name_ar.trim() ? data.name_ar : "";
  data.photos = data.photos ? data.photos : [];

  if (Validator.isEmpty(data.name)) {
    errors.name = "Name field is required";
  }

  if (Validator.isEmpty(data.description_ar)) {
    errors.description_ar = "Description arabic field is required";
  }

  if (Validator.isEmpty(data.description)) {
    errors.description = "Description  field is required";
  }
  if (Validator.isEmpty(data.name_ar)) {
    errors.name_ar = "Name arabic field is required";
  }
  if (!isEmpty(data.photos.length === 0)) {
    errors.photos = "Photos is required";
  }

  return {
    errors,
    isValid: Object.keys(errors).length === 0,
  };
};

export const editCategoryvalidation = (data) => {
  let errors = {};

  data.name = data.name.trim() ? data.name : "";
  data.description_ar = data.description_ar.trim() ? data.description_ar : "";
  data.description = data.description.trim() ? data.description : "";
  data.name_ar = data.name_ar.trim() ? data.name_ar : "";
  //data.photos = data.photos ? data.photos : [];

  if (Validator.isEmpty(data.name)) {
    errors.name = "Name field is required";
  }

  if (Validator.isEmpty(data.description_ar)) {
    errors.description_ar = "Description arabic field is required";
  }

  if (Validator.isEmpty(data.description)) {
    errors.description = "Description  field is required";
  }
  if (Validator.isEmpty(data.name_ar)) {
    errors.name_ar = "Name arabic field is required";
  }
  if (!isEmpty(data.photos) && !isEmpty(data.photos.length === 0)) {
    errors.photos = "Photos is required";
  }

  return {
    errors,
    isValid: Object.keys(errors).length === 0,
  };
};

export const subscriptionvalidation = (data) => {
  let errors = {};

  data.name = data.name.trim() ? data.name : "";
  data.description_ar = data.description_ar.trim() ? data.description_ar : "";
  data.description = data.description.trim() ? data.description : "";
  data.name_ar = data.name_ar.trim() ? data.name_ar : "";
  data.photos = data.photos ? data.photos : [];
  data.price = data.price ? data.price : "";
  data.credits = data.credits ? data.credits : "";

  if (Validator.isEmpty(data.name)) {
    errors.name = "Name field is required";
  }

  if (Validator.isEmpty(data.description_ar)) {
    errors.description_ar = "Description arabic field is required";
  }

  if (Validator.isEmpty(data.description)) {
    errors.description = "Description  field is required";
  }
  if (Validator.isEmpty(data.name_ar)) {
    errors.name_ar = "Name arabic field is required";
  }
  if (!isEmpty(data.photos.length === 0)) {
    errors.photos = "Photos is required";
  }
  if (!data.price) {
    errors.price = "Price field is required";
  }
  if (!data.credits) {
    errors.credits = "Credits field is required";
  }
  return {
    errors,
    isValid: Object.keys(errors).length === 0,
  };
};

export const editSubscriptionvalidation = (data) => {
  let errors = {};

  data.name = data.name.trim() ? data.name : "";
  data.description_ar = data.description_ar.trim() ? data.description_ar : "";
  data.description = data.description.trim() ? data.description : "";
  data.name_ar = data.name_ar.trim() ? data.name_ar : "";
  // data.photos = data.photos ? data.photos : [];
  data.price = data.price ? data.price : "";
  data.credits = data.credits ? data.credits : "";

  if (Validator.isEmpty(data.name)) {
    errors.name = "Name field is required";
  }

  if (Validator.isEmpty(data.description_ar)) {
    errors.description_ar = "Description arabic field is required";
  }

  if (Validator.isEmpty(data.description)) {
    errors.description = "Description  field is required";
  }
  if (Validator.isEmpty(data.name_ar)) {
    errors.name_ar = "Name arabic field is required";
  }
  if (!isEmpty(data.photos) && !isEmpty(data.photos.length === 0)) {
    errors.photos = "Photos is required";
  }
  if (!data.price) {
    errors.price = "Price field is required";
  }
  if (!data.credits) {
    errors.credits = "Credits field is required";
  }
  return {
    errors,
    isValid: Object.keys(errors).length === 0,
  };
};

export const addScheduleValidation = (data) => {
  let errors = {};
  data.class_id = data.class_id ? data.class_id : "";
  data.coach_id = data.coach_id ? data.coach_id : "";
  data.start_time = data.start_time ? data.start_time : "";
  data.duration = data.duration ? data.duration : "";
  data.routine = data.routine ? data.routine : "";
  data.spot = data.spot ? data.spot : "";
  data.price = data.price ? data.price : "";
  data.start_date = data.start_date ? data.start_date : "";
  data.end_date = data.end_date ? data.end_date : "";
  data.close_start_time = data.close_start_time ? data.close_start_time : "";

  if (!data.class_id) {
    errors.class_id = "Class Name field is required";
  }
  if (!data.coach_id) {
    errors.coach_id = "Coach Name field is required";
  }
  if (!data.start_time) {
    errors.start_time = "Start time field is required";
  }
  if (!data.duration) {
    errors.duration = "Duration field is required";
  }
  if (!data.spot) {
    errors.spot = "Spot field is required";
  }
  if (!data.price) {
    errors.price = "Price field is required";
  }
  if (Validator.isEmpty(data.routine)) {
    errors.routine = "Frequency field is required";
  }
  if (!data.start_date) {
    errors.start_date = "Start date field is required";
  }
  if (!data.end_date) {
    errors.end_date = "End date field is required";
  }
  if (!data.close_start_time) {
    errors.close_start_time = "Close Start time field is required";
  }

  return {
    errors,
    isValid: Object.keys(errors).length === 0,
  };
};

export const editScheduleValidation = (data) => {
  let errors = {};
  // data.class_id = data.class_id ? data.class_id : "";
  data.coach_id = data.coach_id ? data.coach_id : "";
  data.start_time = data.start_time ? data.start_time : "";
  data.duration = data.duration ? data.duration : "";
  data.routine = data.routine ? data.routine : "";
  data.spot = data.spot ? data.spot : "";
  data.start_date = data.start_date ? data.start_date : "";
  data.end_date = data.end_date ? data.end_date : "";
  data.close_start_time = data?.close_start_time || 0;
  //  data.price = data.price ? data.price : "";

  /* if (Validator.isEmpty(data.class_id)) {
    errors.class_id = "Class Name field is required";
  } */
  if (!data.coach_id) {
    errors.coach_id = "Coach Name field is required";
  }
  if (!data.start_time) {
    errors.start_time = "Start time field is required";
  }
  if (!data.duration) {
    errors.duration = "Duration field is required";
  }
  if (!data.spot) {
    errors.spot = "Spot field is required";
  }
  /*   if (!data.price) {
    errors.price = "Price field is required";
  } */
  if (Validator.isEmpty(data.routine)) {
    errors.routine = "Frequency field is required";
  }
  if (!data.start_date) {
    errors.start_date = "Start date field is required";
  }
  if (!data.end_date) {
    errors.end_date = "End date field is required";
  }
  // if (data.close_start_time) {
  //   console.log("close_start_time", data);
  //   errors.close_start_time = "Close Start time field is required";
  // }

  return {
    errors,
    isValid: Object.keys(errors).length === 0,
  };
};
export const editScheduleValidationAdmin = (data) => {
  let errors = {};
  // data.class_id = data.class_id ? data.class_id : "";
  // data.coach_id = data.coach_id ? data.coach_id : "";
  data.start_time = data.start_time ? data.start_time : "";
  data.duration = data.duration ? data.duration : "";
  data.routine = data.routine ? data.routine : "";
  data.spot = data.spot ? data.spot : "";
  data.close_start_time = data.close_start_time ? data.close_start_time : "";

  /* if (Validator.isEmpty(data.class_id)) {
    errors.class_id = "Class Name field is required";
  } */

  if (!data.start_time) {
    errors.start_time = "Start time field is required";
  }
  if (!data.duration) {
    errors.duration = "Duration field is required";
  }
  if (!data.spot) {
    errors.spot = "Spot field is required";
  }
  if (Validator.isEmpty(data.routine)) {
    errors.routine = "Frequency field is required";
  }
  if (!data.close_start_time) {
    errors.close_start_time = "Close Start time field is required";
  }

  return {
    errors,
    isValid: Object.keys(errors).length === 0,
  };
};

export const settingValidation = (data) => {
  let errors = {};
  data.amount = data.amount ? data.amount : "";

  if (isEmpty(data.amount)) {
    errors.amount = "Credit Price field is required";
  }

  return {
    errors,
    isValid: Object.keys(errors).length === 0,
  };
};

export const passwordValidation = () => {
  let errors = {};

  // data.confirm_password = data.confirm_password ? data.confirm_password : "";
  // data.password = data.password ? data.password : "";

  /* if (Validator.isEmpty(data.confirm_password)) {
    errors.confirm_password = "Confirm Password field is required";
  } */

  return {
    errors,
    isValid: Object.keys(errors).length === 0,
  };
};
