import axios from "axios";
import { API_URI } from "../../utils/config";
import { clearEmpties } from "../../utils/helpers";

import {
  GET_ERRORS,
  SCHEDULE_CANCELLED_REPORT,
  DELETE_AND_CANCEL_RESPONSE,
  DELETE_AND_CANCEL_RESPONSE_FALSE,
  GET_ALL_SCHEDULE,
  SCHEDULE_DATES,
  GET_SCHEDULE,
  GET_CLEAR_CLASS,
  GET_ALL_CLASSES,
  GET_CLASS,
  GET_SINGLE_CLASS,
  GET_SCHEDULE_STATS,
  CLEAR_LOADING,
  LOADING,
  CLEAR_ERRORS,
  GET_ALL_SETTING,
  POP_UP_CLOSE,
  CLEAR_POP_UP_CLOSE,
} from "../types";

export const addSchedule = (scheduleData) => (dispatch) => {
  axios
    .post(`${API_URI}/class_schedules`, scheduleData)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        dispatch(getAllSchedule());
        dispatch({
          type: POP_UP_CLOSE,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error,
      });
    });
};
export const clearErrors = () => (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};

export const getAllSchedule =
  (extraFilters = {}) =>
  async (dispatch) => {
    dispatch(setLoading());
    let gym_id = localStorage.getItem("gym_id");
    let filters = JSON.stringify({
      where: {
        ...clearEmpties({ gym_id }),
        ...clearEmpties(extraFilters),
      },
    });
    console.log(filters);
    axios
      .get(`${API_URI}/all_class_schedules?filter=${filters}`)
      .then((res) => {
        if (res.data.error.code) {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.error,
          });
        } else {
          const { data } = res.data;
          dispatch({
            type: GET_ALL_SCHEDULE,
            payload: data,
          });
          dispatch(clearLoading());
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err,
        });
        dispatch(clearLoading());
      });
  };
export const clearClass = () => (dispatch) => {
  dispatch({
    type: GET_CLEAR_CLASS,
  });
};

// Get single Schedule Details in view
export const getSchedule = (id) => (dispatch) => {
  axios
    .get(`${API_URI}/class_schedules/${id}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_SCHEDULE,
          payload: data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error.message,
      });
    });
};

// Update Schedule
export const updateSchedule = (scheduleData, id) => (dispatch, getState) => {
  let schedules = [...getState().sch.schedules];

  axios
    .put(`${API_URI}/class_schedules/${id}`, scheduleData)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        schedules = schedules.map((gym) => {
          if (gym.id === data.id) {
            gym = { ...gym, ...data };
          }

          return gym;
        });

        dispatch({
          type: GET_ALL_SCHEDULE,
          payload: schedules,
        });

        dispatch(getClassAdmin(data.class_id));

        dispatch({
          type: POP_UP_CLOSE,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error,
      });
    });
};
// Get single Class Details in view
export const getClassAdmin = (id) => (dispatch) => {
  axios
    .get(`${API_URI}/getSingleClasses/${id}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_CLASS,
          payload: data,
        });
        dispatch({
          type: GET_SINGLE_CLASS,
          payload: data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};
export const getAllClasses = () => async (dispatch) => {
  dispatch(setLoading());
  axios
    .get(`${API_URI}/getAllClasses`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_ALL_CLASSES,
          payload: data,
        });
        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error.message,
      });
      dispatch(clearLoading());
    });
};

// Delete gym
export const deleteSchedule = (id) => (dispatch, getState) => {
  let schedules = [...getState().sch.schedules];

  axios
    .delete(`${API_URI}/class_schedules/${id}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        schedules = schedules.filter((gym) => gym.id !== id);
        dispatch({
          type: GET_ALL_SCHEDULE,
          payload: schedules,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error,
      });
      dispatch(responseMessage());
    });
};

export const getStatsSchedule = () => async (dispatch) => {
  let gym_id = localStorage.getItem("gym_id");
  const filter = JSON.stringify({ where: { gym_id: gym_id } });
  axios
    .get(`${API_URI}/class_schedules/stats?filter=${filter}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;

        dispatch({
          type: GET_SCHEDULE_STATS,
          payload: data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

export const clearPopupClose = () => (dispatch) => {
  dispatch({
    type: CLEAR_POP_UP_CLOSE,
  });
};

export const getAllCreditAmount = () => async (dispatch) => {
  axios
    .get(`${API_URI}/settings`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_ALL_SETTING,
          payload: data[0].amount,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

export const getAllScheduleCalendar = (date) => async (dispatch) => {
  dispatch(setLoading());

  let gym_id = localStorage.getItem("gym_id");
  const filter = JSON.stringify({
    where: { gym_id: gym_id },
    inScheduleDate: { date: date },
  });
  axios
    .get(`${API_URI}/class_schedules?filter=${filter}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_ALL_SCHEDULE,
          payload: data,
        });
        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
      dispatch(clearLoading());
    });
};

export const setLoading = () => {
  return {
    type: LOADING,
  };
};

export const clearLoading = () => {
  return {
    type: CLEAR_LOADING,
  };
};
export const responseMessage = () => {
  return {
    type: DELETE_AND_CANCEL_RESPONSE,
  };
};
export const responseMessageFalse = () => {
  return {
    type: DELETE_AND_CANCEL_RESPONSE_FALSE,
  };
};

export const getAllScheduleAdminLogin = () => async (dispatch) => {
  dispatch(setLoading());
  axios
    .get(`${API_URI}/class_schedules`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_ALL_SCHEDULE,
          payload: data,
        });
        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
      dispatch(clearLoading());
    });
};

export const CancelSchedule = (scheduleData, id) => (dispatch, getState) => {
  let schedule_dates = [...getState().sch.schedule_dates];
  axios
    .post(`${API_URI}/schedule_dates/cancel`, scheduleData)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;

        schedule_dates.push(data);
        dispatch({
          type: SCHEDULE_DATES,
          payload: schedule_dates,
        });
        dispatch(getSchedule(id));
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error,
      });
    });
};

export const getAllScheduleCancelledReport =
  (start_date = "", end_date = "") =>
  async (dispatch) => {
    dispatch(setLoading());

    let gym_id = localStorage.getItem("gym_id");
    let url = `/all_booking_classes`;
    let filter = "";
    if (start_date && end_date) {
      filter = JSON.stringify({
        where: {
          createdAt: {
            $between: [`${start_date} 00:00:01`, `${end_date} 23:59:59`],
          },
          gym_id: gym_id,
          is_admin_cancel: true,
        },
      });
      url = `${url}?filter=${filter}`;
    } else {
      filter = JSON.stringify({
        where: { gym_id: gym_id, is_admin_cancel: true },
      });
      url = `${url}?filter=${filter}`;
    }
    axios
      .get(`${API_URI}/${url}`)
      .then((res) => {
        if (res.data.error.code) {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.error,
          });
        } else {
          const { data } = res.data;
          dispatch({
            type: SCHEDULE_CANCELLED_REPORT,
            payload: data,
          });
          dispatch(clearLoading());
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data.error,
        });
        dispatch(clearLoading());
      });
  };
