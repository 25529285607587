import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "../common/Modal";
import Validator from "validator";
import {
  addSchedule,
  clearPopupClose,
  getAllCreditAmount,
} from "../../store/schedules/scheduleAction";
import { addScheduleValidation } from "../../validation/validation";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "react-time-picker";
import { getAllActiveClasses } from "../../store/classes/classesAction";
import { getCoachSchedule } from "../../store/coaches/coachesAction";
import { clearErrors } from "../../store/errors/errorAction";
import isEmpty from "../../validation/is-empty";
import DatePicker from "react-datepicker";
import moment from "moment";

export class addschedule extends Component {
  constructor(props) {
    super(props);
    const end_date = new Date();
    this.state = {
      class_id: "",
      start_time: "",
      duration: "",
      routine: "Manual",
      end_date: moment(end_date).endOf("year").toDate(),
      credits: "",
      end_time: "",
      start_date: new Date(),
      routine_days: [],
      routine_days_selected: [
        { name: "Monday", id: 0 },
        { name: "Tuesday", id: 1 },
        { name: "Wednesday", id: 2 },
        { name: "Thursday", id: 3 },
        { name: "Friday", id: 4 },
        { name: "Saturday", id: 5 },
        { name: "Sunday", id: 6 },
      ],
      rout: [],
      frequencydata: [
        { name: "Weekdays", id: 1 },
        { name: "Weekend", id: 2 },
        { name: "Daily", id: 3 },
        { name: "Manual", id: 4 },
      ],
      count: "",
      coach_id: "",
      endtim: "",
      gym_id: "",
      errors: {},
      spot: "",
      is_virtual: false,
      zoom_link: "",
      zoom_password: "",
      amounts: this.props.sch.credit_amount,
      amount: this.props.sch.credit_amount,
      price: "",
      close_start_time: "",
    };
  }

  handleKeyDown = (event) => {
    if (event.keyCode === 27 && event.key === "Escape") {
      this.props.handleCancelAddUser();
    }
  };

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown);
    this.props.getCoachSchedule();
    this.props.clearErrors();
    this.props.getAllCreditAmount();
    this.props.getAllActiveClasses();
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  static getDerivedStateFromProps(props, state) {
    if (!isEmpty(props.errors.popup)) {
      props.handleCancelAddUser();
      props.clearErrors();
      props.clearPopupClose();
    }
    if (!isEmpty(props.errors.error)) {
      return {
        errors: { common: props.errors.error },
      };
    } else {
      if (state.errors.common) {
        delete state.errors.common;
      }
    }
    return null;
  }

  onChangeRoutine = (e) => {
    this.setState({ routine: e.target.value });
  };

  onChangecount = (e) => {
    e.preventDefault();
    let { count } = this.state;
    this.setState({
      count: count + 1,
      amount: (count + 1) * this.state.amounts,
    });
  };

  onChangecountminus = (e) => {
    e.preventDefault();
    let { count } = this.state;
    if (count > 1) {
      this.setState({
        count: count - 1,
        amount: (count - 1) * this.state.amounts,
      });
    }
  };

  onstarttimeadd = () => {
    let { start_time, duration } = this.state;

    var hms = start_time + ":00"; // your input string
    var a = hms.split(":"); // split it at the colons

    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    var seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];

    duration = 60 * duration;

    let end_time = (seconds + duration) * 1000;

    function msToTime(duration) {
      var seconds = parseInt((duration / 1000) % 60),
        minutes = parseInt((duration / (1000 * 60)) % 60),
        hours = parseInt((duration / (1000 * 60 * 60)) % 24);

      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      return hours + ":" + minutes + ":" + seconds;
    }

    let timeend = msToTime(end_time);
    return timeend;
    //this.setState({end_time :timeend })
  };

  handleCancelAddUser = () => {
    this.props.handleCancelAddUser();
    this.props.clearErrors();
  };
  handleCancelEditSchedule = () => {
    this.props.handleCancelEditSchedule();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let end_time = this.onstarttimeadd();
    const { coaches } = this.props.coach;

    const {
      class_id,
      start_time,
      duration,
      routine,
      end_date,
      start_date,
      coach_id,
      count,
      spot,
      is_virtual,
      zoom_password,
      zoom_link,
      price,
      close_start_time,
    } = this.state;

    let rout = [...this.state.routine_days];
    let rou = rout.map((rou) => {
      return parseInt(rou);
    });
    let start_date_submit;
    let end_date_submit;
    if (start_date) {
      let d = start_date;
      start_date_submit =
        d.getFullYear() +
        "-" +
        `${d.getMonth() + 1}`.padStart(2, "0") +
        "-" +
        `${d.getDate()}`.padStart(2, "0");
    }
    if (end_date) {
      let f = end_date;
      end_date_submit =
        f.getFullYear() +
        "-" +
        `${f.getMonth() + 1}`.padStart(2, "0") +
        "-" +
        `${f.getDate()}`.padStart(2, "0");
    }

    const { id } = this.props.auth.user.gym;

    const scheduleData = {
      class_id,
      start_time,
      duration,
      routine,
      //  end_date: sentdates ? sentdates.end_date : "",
      end_date: end_date_submit,
      credits: count,
      end_time,
      // start_date :sentdates ? sentdates.start_date : "",
      // routine_days: rou,
      start_date: start_date_submit,
      coach_id: coach_id || (coaches || [])[0]?.id,
      gym_id: id,
      spot,
      price,
      is_virtual,
      close_start_time,
    };

    /*   if (rou.length > 0) {
      scheduleData.routine_days = rou; 
    } */
    if (routine === "Manual") {
      scheduleData.routine_days = rou;
    }
    if (is_virtual !== false) {
      scheduleData.zoom_password = zoom_password;
      scheduleData.zoom_link = zoom_link;
    }
    if (price === 0) {
      scheduleData.credits = 0;
    }
    if (count) {
      scheduleData.credits = Math.ceil(count);
    }

    const { errors, isValid } = addScheduleValidation(scheduleData);

    if (is_virtual === true && zoom_password === "") {
      errors.zoom_password = "Zoom Password is Required";
    }
    if (is_virtual === true && zoom_link === "") {
      errors.zoom_link = "Zoom Link is Required";
    }
    if (!Validator.isURL(zoom_link) && is_virtual === true) {
      errors.zoom_link = "Required valid url";
    }

    if (isValid && Object.keys(errors).length === 0) {
      this.props.clearErrors();
      this.props.addSchedule(scheduleData);
    } else {
      this.setState({ errors });
    }
  };
  handleChange = (e) => {
    const errors = this.state.errors;
    if (errors[e.target.name]) {
      delete errors[e.target.name];
      delete errors.common;
    }
    this.props.clearErrors();
    this.setState({ [e.target.name]: e.target.value, errors });
    // this.setState({ [e.target.name]: e.target.value });
  };
  toggleChange = () => {
    this.setState({
      is_virtual: !this.state.is_virtual,
    });
  };

  handleChangeNumber = (e) => {
    e.preventDefault();

    if (
      Number(e.target.value) ||
      e.target.value === "" ||
      parseInt(e.target.value) === 0
    ) {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  handleChangeAmount = (e) => {
    if (
      Number(e.target.value) ||
      e.target.value === "" ||
      parseInt(e.target.value) === 0
    ) {
      this.setState({
        [e.target.name]: e.target.value,
        count: e.target.value / this.props.sch.credit_amount,
      });
    }
  };

  onChange = (start_time) => {
    this.setState({ start_time });
  };
  //onChangeendtime = end_time => this.setState({ end_time });

  onCloseStartTimeChange = (e) => {
    e.preventDefault();

    if (
      Number(e.target.value) ||
      e.target.value === "" ||
      parseInt(e.target.value) === 0
    ) {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  onSelect = (e) => {
    const value = e.target.value;
    if (!this.state.routine_days.includes(value)) {
      this.setState({
        routine_days: [...this.state.routine_days, value],
      });
    } else {
      this.setState({
        routine_days: this.state.routine_days.filter((val) => val !== value),
      });
    }
  };
  handleChangeDate = (date) => {
    let datenow = new Date();
    let d = date;
    let start_date =
      d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();

    let datenew =
      datenow.getFullYear() +
      "-" +
      (datenow.getMonth() + 1) +
      "-" +
      datenow.getDate();

    if (date >= datenow) {
      this.setState({
        start_date: date,
      });
    } else {
      if (start_date === datenew) {
        this.setState({ start_date: date });
      }
    }
  };

  handleChangeEndDate = (date) => {
    let { start_date } = this.state;
    let datenow = new Date();

    let datenew =
      datenow.getFullYear() +
      "-" +
      (datenow.getMonth() + 1) +
      "-" +
      datenow.getDate();
    if (date >= start_date) {
      this.setState({
        end_date: date,
      });
    } else {
      if (start_date === datenew) {
        this.setState({
          end_date: date,
        });
      }
    }
  };

  render() {
    const {
      start_time,
      duration,
      routine,
      end_date,
      start_date,
      frequencydata,
      coach_id,
      errors,
      spot,
      is_virtual,
      zoom_password,
      zoom_link,
      price,
      close_start_time,
    } = this.state;
    const { classes } = this.props.cla;
    const { coaches } = this.props.coach;

    return (
      <Modal
        id="addArea"
        key="addArea"
        open={true}
        size={"medium"}
        header="Schedule a class"
        onClose={this.handleCancelAddUser}
      >
        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-md-offset-1 col-md-4 col-md-offset-1">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Class Name</label>
                <select
                  className="gyminputdateschedule sel-mac"
                  name="class_id"
                  onChange={this.handleChange}
                >
                  <option value="">Select Class </option>
                  {classes.map((clas) => (
                    <option value={clas.id} key={clas.id}>
                      {clas.name}
                    </option>
                  ))}
                </select>
                {errors.class_id ? (
                  <span style={{ color: "red" }}>{errors.class_id}</span>
                ) : null}
              </div>
            </div>
            <div className="col-md-offset-1 col-md-4 col-md-offset-1">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Instructor</label>
                <select
                  className="gyminputdateschedule sel-mac"
                  name="coach_id"
                  onChange={this.handleChange}
                  value={coach_id || (coaches || [])[0]?.id}
                >
                  <option value={coach_id}>Select Instructor</option>
                  {coaches.map((coa) => (
                    <option value={coa.id} key={coa.id}>
                      {coa.name}
                    </option>
                  ))}
                </select>
                {/*  <span className="glyphicon glyphicon-lock form-control-feedback"></span> */}
                {errors.coach_id ? (
                  <span style={{ color: "red" }}>{errors.coach_id}</span>
                ) : null}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-offset-1 col-md-4 col-md-offset-1">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Start Date</label>
                <DatePicker
                  className="gyminputdateschedule"
                  selected={start_date}
                  onChange={this.handleChangeDate}
                >
                  {/*   {this.state.start_date} */}
                </DatePicker>
                {errors.start_date ? (
                  <span style={{ color: "red" }}>{errors.start_date}</span>
                ) : null}
              </div>
            </div>
            <div className="col-md-offset-1 col-md-4 col-md-offset-1">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">End Date</label>
                <DatePicker
                  className="gyminputdateschedule"
                  selected={end_date}
                  onChange={this.handleChangeEndDate}
                />
                {errors.end_date ? (
                  <span style={{ color: "red" }}>{errors.end_date}</span>
                ) : null}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-offset-1 col-md-4 col-md-offset-1">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">
                  Booking Cut off Time (In Minutes)
                </label>
                <input
                  type="text"
                  className="gyminputdateschedule"
                  name="close_start_time"
                  placeholder="Mins"
                  value={close_start_time}
                  onChange={this.onCloseStartTimeChange}
                />
                {errors.close_start_time ? (
                  <span style={{ color: "red" }}>
                    {errors.close_start_time}
                  </span>
                ) : null}
              </div>
            </div>
            <div className="col-md-offset-1 col-md-4 col-md-offset-1">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Start Time</label>
                <TimePicker
                  className="gyminputdateschedule"
                  onChange={this.onChange}
                  disableClock
                  value={start_time}
                />
                {errors.start_time ? (
                  <span style={{ color: "red" }}>{errors.start_time}</span>
                ) : null}
              </div>
            </div>
            <div className="col-md-offset-1 col-md-4 col-md-offset-1">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">
                  Duration (In Minutes)
                </label>
                <input
                  type="text"
                  className="gyminputdateschedule"
                  name="duration"
                  placeholder="Mins"
                  value={duration}
                  onChange={this.handleChangeNumber}
                />
                {errors.duration ? (
                  <span style={{ color: "red" }}>{errors.duration}</span>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-offset-1 col-md-4 col-md-offset-1">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Frequency</label>
                <select
                  className="gyminputdateschedule sel-mac"
                  id="category_id"
                  name="routine"
                  onChange={this.onChangeRoutine}
                  value={routine}
                >
                  <option value="">Select</option>

                  {frequencydata.map((cat, id) => (
                    <option key={id} value={cat.name}>
                      {cat.name}
                    </option>
                  ))}
                </select>
                {errors.routine ? (
                  <span style={{ color: "red" }}>{errors.routine}</span>
                ) : null}
              </div>
            </div>

            {routine === "Manual" ? (
              <div className="col-md-offset-1 col-md-4 col-md-offset-1">
                <div className="">
                  <label htmlFor="exampleInputEmail1">Days</label>
                </div>
                <select
                  id="days"
                  name="days"
                  className="form-select"
                  style={{
                    fontSize: 14,
                    paddingRight: 16,
                    paddingLeft: 4,
                    width: 250,
                    marginBottom: 24,
                  }}
                  multiple
                  onChange={this.onSelect}
                  //   value={this.state.routine_days}
                >
                  {this.state.routine_days_selected.map((day) => {
                    return (
                      <option
                        key={day.id}
                        value={day.id}
                        style={{
                          padding: 8,
                          backgroundColor: this.state.routine_days?.includes(
                            day.id.toString()
                          )
                            ? "lightblue"
                            : "white",
                        }}
                      >
                        {day.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            ) : null}
          </div>
          <div className="row">
            {/* <div className="col-md-offset-1 col-md-4 col-md-offset-1">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Credits</label>

                <input
                  type="text"
                  className="gyminputdateschedule"
                  id="name"
                  name="credits"
                  placeholder="ClassTap Spots"
                  value={count}
                  onChange={this.handleChangeNumber}
                />
              </div>
              <button onClick={this.onChangecount}>+</button>
              <button onClick={this.onChangecountminus}>-</button>
            </div>  */}

            <div className="col-md-offset-1 col-md-4 col-md-offset-1">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Spots</label>

                <input
                  type="text"
                  className="gyminputdateschedule"
                  id="name"
                  name="spot"
                  placeholder="Spot"
                  value={spot}
                  onChange={this.handleChangeNumber}
                />
                {errors.spot ? (
                  <span style={{ color: "red" }}>{errors.spot}</span>
                ) : null}
              </div>
            </div>
            <div className="col-md-offset-1 col-md-4 col-md-offset-1">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Price</label>

                <input
                  type="text"
                  className="gyminputdateschedule"
                  id="name"
                  name="price"
                  placeholder="Price"
                  value={price}
                  onChange={this.handleChangeAmount}
                />
                {errors.price ? (
                  <span style={{ color: "red" }}>{errors.price}</span>
                ) : null}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-offset-1 col-md-4 col-md-offset-1">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Virtual</label>

                <input
                  type="checkbox"
                  className="gyminputdateschedule-checkbox"
                  defaultChecked={false}
                  value={is_virtual}
                  onChange={this.toggleChange}
                />
              </div>
            </div>
          </div>
          {is_virtual === true ? (
            <div className="row">
              <div className="col-md-offset-1 col-md-4 col-md-offset-1">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Zoom Link</label>
                  <input
                    type="text"
                    className="gyminputdateschedule"
                    id="name"
                    name="zoom_link"
                    placeholder="Link"
                    value={zoom_link}
                    onChange={this.handleChange}
                  />
                  {errors.zoom_link ? (
                    <span style={{ color: "red" }}>{errors.zoom_link}</span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-offset-1 col-md-4 col-md-offset-1">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Zoom Password</label>
                  <input
                    type="text"
                    className="gyminputdateschedule"
                    id="name"
                    name="zoom_password"
                    placeholder="Zoom Password"
                    value={zoom_password}
                    onChange={this.handleChange}
                  />
                  {errors.zoom_password ? (
                    <span style={{ color: "red" }}>{errors.zoom_password}</span>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}

          <div className="box-footer" style={{ textAlign: "center" }}>
            <button type="submit" className="btn btn-primary">
              Add
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  sch: state.sch,
  cla: state.cla,
  coach: state.coach,
  errors: state.errors,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  addSchedule,
  getCoachSchedule,
  clearErrors,
  getAllCreditAmount,
  clearPopupClose,
  getAllActiveClasses,
})(addschedule);
