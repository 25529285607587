import React, { Component } from "react";
import Modal from "../common/Modal";

export class DeleteSchedule extends Component {
  closeDeleteOrCancelPopup = () => {
    this.props.closeDeleteOrCancelPopup();
  };

  handleDeleteClick = () => {
    this.props.handleDeleteClick(this.props.schedule.id);
  };
  render() {
    return (
      <Modal
        id="delete-schedule"
        key="delete-schedule"
        open={true}
        size={"format"}
        header="Delete Schedule"
        onClose={this.closeDeleteOrCancelPopup}
      >
        <div className="row">
          <div className="col-md-12">
            <label>Are you sure want to delete this Schedule?</label>
          </div>
          <div className="col-md-12" style={{ marginTop: "20px" }}>
            <div className="col-md-6">
              <button
                className="btn btn-danger"
                onClick={this.handleDeleteClick}
              >
                Yes
              </button>
            </div>
            <div className="col-md-6">
              <button
                className="btn btn-status"
                onClick={this.handleCancelDeleteUser}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default DeleteSchedule;
