import {
  GET_ALL_BOOKING,
  GET_BOOKING_CANCELLATION_REPORT,
  GET_ALL_BOOKING_GYM_REPORT,
  GET_ALL_BOOKINGS,
  GET_ALL_UPCOMING_BOOKING,
  GET_BOOKING,
  GET_BOOKING_STATS,
  GET_CLEAR_CATEGORY,
  ADD_BOOKING,
  SET_CANCEL_FILTER,
  SAVE_BOOKINGS
} from "../types";

const initialState = {
  bookings: [],
  booking: {},
  bookingStats: [],
  upcoming_booking: [],
  admin_bookings: [],
  gym_booking: [],
  booking_cancellation_report: [],
  filterCancelBooking: "0"
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_BOOKINGS:
      return {
        ...state,
        bookings: action.payload
      }

    case SET_CANCEL_FILTER:
      return {
        ...state,
        filterCancelBooking: action.payload,
      };
    case ADD_BOOKING:
      return {
        ...state,
        bookings: [action.payload, ...state.bookings,],
      };

    case GET_ALL_BOOKING:
      return {
        ...state,
        bookings: action.payload,
      };
    case GET_BOOKING:
      return {
        ...state,
        booking: action.payload,
      };
    case GET_BOOKING_STATS:
      return {
        ...state,
        bookingStats: action.payload,
      };
    case GET_CLEAR_CATEGORY:
      return {
        ...state,
        booking: action.payload,
      };
    case GET_ALL_UPCOMING_BOOKING:
      return {
        ...state,
        bookings: action.payload,
      };
    case GET_ALL_BOOKINGS:
      return {
        ...state,
        bookings: action.payload,
      };
    case GET_ALL_BOOKING_GYM_REPORT:
      return {
        ...state,
        gym_booking: action.payload,
      };
    case GET_BOOKING_CANCELLATION_REPORT:
      return {
        ...state,
        bookings: action.payload,
      };

    default:
      return state;
  }
}
