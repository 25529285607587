import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { EntityViewLayout } from "../entityLayout/entityViewLayout";
import moment from "moment";
import { getAllSurvey } from "../../store/survey/surveyAction";

export const ShowSurveyTable = () => {
  const survey = useSelector((state) => state.survey);
  const { isLodaing } = useSelector((state) => state.errors);
  const dispatch = useDispatch();

  let date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();
  const lastDayNextMonth = new Date(y, m + 1, 0);
  const firstDayPrevMonth = new Date(y, m - 1, 1);

  const tableProps = {
    name: "Survey",
    routePath: "/survey-reports",
    addNewText: "Add a new Survey",
    isBreadcrumbs: false,
    isAddNew: false,
    isSearch: false,
    isFilters: false,
    isDateFilters: false,
    isPaidFilters: false,
    isPaymentTypeFilters: false,
    isCsvConvert: true,
    isExcelConvert: true,
    defaultDateRange: {
      start_date: firstDayPrevMonth,
      end_date: lastDayNextMonth,
    },
    columns: [
      {
        Header: "Sr No",
        maxWidth: 60,
        filterable: false,
        id: "id",
        accessor: "id",
      },
      {
        Header: "Member Id",
        maxWidth: 75,
        filterable: false,
        id: "member_id",
        accessor: "member_id",
      },
      {
        Header: "Member Name",
        id: "member_name",
        accessor: "member_name",
        filterable: false,
      },
      {
        Header: "Member Phone",
        id: "member_phone",
        accessor: "member_phone",
        filterable: false,
      },
      {
        Header: "Member Email",
        id: "member_email",
        accessor: "member_email",
        filterable: false,
      },
      {
        Header: "Membership Name",
        id: "membership_name",
        accessor: "membership_name",
        filterable: false,
      },
      {
        Header: "Reason",
        id: "reason",
        accessor: "reason",
        filterable: false,
      },
      {
        Header: "Membership Expiry Date",
        id: "membership_expiry_date",
        accessor: "membership_expiry_date",
        filterable: false,
      },
      {
        Header: "Remaining Credits",
        id: "remaining_credits",
        accessor: "remaining_credits",
        filterable: false,
      },
      {
        Header: "Survey Created Date",
        id: "survey_created_date",
        filterable: false,
        accessor: "survey_created_date",
        Cell: function getDateInfo(row) {
          return (
            <div>{moment(row.original.updatedAt).format("YYYY-MM-DD")}</div>
          );
        },
      },
    ],
  };

  const searchFields = ["first_name", "user_id"];
  let history = useHistory();

  const csvHeaders = [
    {
      label: "Sr No.",
      key: "id",
    },
    {
      label: "Member ID",
      key: "member_id",
    },
    {
      label: "Member Name",
      key: "member_name",
    },
    {
      label: "Member Phone",
      key: "member_phone",
    },
    {
      label: "Member Email",
      key: "member_email",
    },
    {
      label: "Membership Name",
      key: "membership_name",
    },
    {
      label: "Reason",
      key: "reason",
    },
    {
      label: "Membership Expiry Date",
      key: "membership_expiry_date",
    },
    {
      label: "Remaining Credits",
      key: "remaining_credits",
    },
    {
      label: "Survey Created Date",
      key: "survey_created_date",
    },
  ];

  const excelHeaders = [
    {
      label: "Sr No.",
      fieldName: "id",
      valuePath: "id",
    },
    {
      label: "Member ID",
      fieldName: "member_id",
      valuePath: "member_id",
    },
    {
      label: "Member Name",
      fieldName: "first_name",
      valuePath: "user.first_name",
    },
    {
      label: "Member Phone",
      fieldName: "member_phone",
      valuePath: "member_phone",
    },
    {
      label: "Member Email",
      fieldName: "member_email",
      valuePath: "member_email",
    },
    {
      label: "Membership Name",
      fieldName: "membership_name",
      valuePath: "membership_name",
    },
    {
      label: "Reason",
      fieldName: "reason",
      valuePath: "reason",
    },
    {
      label: "Membership Expiry Date",
      fieldName: "membership_expiry_date",
      valuePath: "membership_expiry_date",
      fieldType: "membership_expiry_date",
    },
    {
      label: "Remaining Credits",
      fieldName: "remaining_credits",
      valuePath: "remaining_credits",
      fieldType: "remaining_credits",
    },
    {
      label: "Survey Created Date",
      fieldName: "survey_created_date",
      valuePath: "survey_created_date",
      fieldType: "survey_created_date",
    },
  ];

  useEffect(() => {
    dispatch(getAllSurvey());
  }, []);

  return (
    <EntityViewLayout
      isLoading={isLodaing}
      searchFields={searchFields}
      items={survey.surveys}
      tableProps={tableProps}
      csvHeaders={csvHeaders}
      excelHeaders={excelHeaders}
      history={history}
      csvFilePrefix="survey_report"
    />
  );
};
