import { GET_ALL_SURVEY } from "../types";

const initialState = {
  survey: [],
  response: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_SURVEY:
      return {
        ...state,
        surveys: action.payload,
      };
    default:
      return state;
  }
}
