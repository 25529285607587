import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./index.css";

class SideMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showme: false,
      pullleft: false,
    };
  }

  showAndHide = () => {
    this.setState({
      showme: !this.state.showme,
      pullleft: !this.state.pullleft,
    });
  };

  render() {
    const { user } = this.props.auth;

    return (
      <>
        <aside className="main-sidebar">
          <section className="sidebar">
            {user.role_id === 1 ? (
              <ul className="sidebar-menu">
                <li
                  className={`${
                    this.props.active === "dashboard" ? "active" : ""
                  } treeview`}
                >
                  <Link to={"/dashboard"}>
                    {<i className="fa fa-dashboard"></i>}
                    <span style={{ marginLeft: "12px" }}>Dashboard</span>
                  </Link>
                </li>

                <li
                  className={`${
                    this.props.active === "members" ? "active" : ""
                  } treeview`}
                >
                  <Link to={"/members"}>
                    <i className="fa fa-user"></i>{" "}
                    <span style={{ marginLeft: "12px" }}>Members</span>
                  </Link>
                </li>
                <li
                  className={`${
                    this.props.active === "gyms" ? "active" : ""
                  } treeview`}
                >
                  <Link to={"/gyms"}>
                    <i className="fas fa-dumbbell"></i>{" "}
                    <span style={{ marginLeft: "12px" }}>Manage Gym</span>
                  </Link>
                </li>
                <li
                  className={`${
                    this.props.active === "viewers" ? "active" : ""
                  } treeview`}
                >
                  <Link to={"/viewers"}>
                    <i className="fas fa-dumbbell"></i>{" "}
                    <span style={{ marginLeft: "12px" }}>
                      Manage Gym Viewer
                    </span>
                  </Link>
                </li>
                <li
                  className={`${
                    this.props.active === "admin-classes" ? "active" : ""
                  } treeview`}
                >
                  <Link to={"/admin-classes"}>
                    <i className="fas fa-burn"></i>{" "}
                    <span style={{ marginLeft: "16px" }}>Classes</span>
                  </Link>
                </li>
                <li
                  className={`${
                    this.props.active === "admin-coaches" ? "active" : ""
                  } treeview`}
                >
                  <Link to={"/admin-coaches"}>
                    <i className="fas fa-chalkboard-teacher"></i>{" "}
                    <span style={{ marginLeft: "10px" }}>Coaches</span>
                  </Link>
                </li>

                <li
                  className={`${
                    this.props.active === "categories" ? "active" : ""
                  } treeview`}
                >
                  <Link to={"/categories"}>
                    <i className="fa fa-list-alt" aria-hidden="true"></i>{" "}
                    <span style={{ marginLeft: "8px" }}>Category</span>
                  </Link>
                </li>
                <li
                  className={`${
                    this.props.active === "subscriptions" ? "active" : ""
                  } treeview`}
                >
                  <Link to={"/subscriptions"}>
                    <i className="fas fa-bookmark"></i>{" "}
                    <span style={{ marginLeft: "15px" }}>Subscriptions</span>
                  </Link>
                </li>
                <li
                  className={`${
                    this.props.active === "all-bookings" ? "active" : ""
                  } treeview`}
                >
                  <Link to={"/all-bookings"}>
                    <i className="fa fa-ticket" aria-hidden="true"></i>{" "}
                    <span style={{ marginLeft: "8px" }}>Bookings</span>
                  </Link>
                </li>
                <li
                  className={`${
                    this.props.active === "admin-gyms" ? "active" : ""
                  } treeview`}
                >
                  <Link to={"/reviews/admin-gyms"}>
                    <i className="fa fa-star" aria-hidden="true"></i>{" "}
                    <span style={{ marginLeft: "8px" }}>Gym Review</span>
                  </Link>
                </li>
                <li
                  className={`${
                    this.props.active === "reviews-admin-classes"
                      ? "active"
                      : ""
                  } treeview`}
                >
                  <Link to={"/reviews/admin-classes"}>
                    <i className="fa fa-star" aria-hidden="true"></i>{" "}
                    <span style={{ marginLeft: "8px" }}>Class Review</span>
                  </Link>
                </li>
                <li
                  className={`${
                    this.props.active === "reviews-admin-coaches"
                      ? "active"
                      : ""
                  } treeview`}
                >
                  <Link to={"/reviews/admin-coaches"}>
                    <i className="fa fa-star" aria-hidden="true"></i>{" "}
                    <span style={{ marginLeft: "8px" }}>Coach Review</span>
                  </Link>
                </li>
                <li
                  className={`${
                    this.props.active === "contact-us" ? "active" : ""
                  } treeview`}
                >
                  <Link to={"/contact-us"}>
                    <i className="fas fa-address-book"></i>{" "}
                    <span style={{ marginLeft: "14px" }}>Contact Us</span>
                  </Link>
                </li>

                <li>
                  <a onClick={() => this.showAndHide()}>
                    <i className="fas fa-chart-bar"></i>{" "}
                    <span
                      className="linkbutton"
                      style={{ marginLeft: "12px" }}
                      onClick={() => this.showAndHide()}
                    >
                      Reports
                    </span>
                    <i
                      className={`fa ${"fa-angle-left"} pull-right`}
                      style={{
                        transform: `rotate(${this.state.showme ? -90 : 0}deg)`,
                        transition: "0.4s",
                      }}
                    />
                  </a>
                  {this.state.showme ? (
                    <ul>
                      <li
                        className={`${
                          this.props.active === "bookings-report"
                            ? "active"
                            : ""
                        } treeview`}
                      >
                        <Link to={"/reports/bookings-report"}>
                          {/* {<i className="fa fa-circle-o"></i>} */}
                          <span className="linkbutton">Bookings Report</span>
                        </Link>
                      </li>
                      <li
                        className={`${
                          this.props.active === "reports-gyms-admin"
                            ? "active"
                            : ""
                        } treeview`}
                      >
                        <Link to={"/reports/gyms-admin"}>
                          {/* {<i className="fa fa-circle-o"></i>} */}
                          <span className="linkbutton">Gym Owner Reports</span>
                        </Link>
                      </li>
                      <li
                        className={`${
                          this.props.active === "reports-classes-admin"
                            ? "active"
                            : ""
                        } treeview`}
                      >
                        <Link to={"/reports/classes-admin"}>
                          {/* {<i className="fa fa-circle-o"></i>} */}
                          <span className="linkbutton">Class Report</span>
                        </Link>
                      </li>
                      <li
                        className={`${
                          this.props.active === "plan-subscription-report"
                            ? "active"
                            : ""
                        } treeview`}
                      >
                        <Link to={"/plan-subscription-report"}>
                          {/* {<i className="fa fa-circle-o"></i>} */}
                          <span className="linkbutton">
                            Members Subscription Report
                          </span>
                        </Link>
                      </li>
                      <li
                        className={`${
                          this.props.active === "financial-report"
                            ? "active"
                            : ""
                        } treeview`}
                      >
                        <Link to={"/financial-report"}>
                          <span className="linkbutton">Financial Report</span>
                        </Link>
                      </li>
                      <li
                        className={`${
                          this.props.active === "reports-all-sessions"
                            ? "active"
                            : ""
                        } treeview`}
                      >
                        <Link to={"/reports/all-sessions"}>
                          <span className="linkbutton">
                            All sessions report
                          </span>
                        </Link>
                      </li>
                      <li
                        className={`${
                          this.props.active === "survey-reports" ? "active" : ""
                        } treeview`}
                      >
                        <Link to={"/survey-reports"}>
                          <span className="linkbutton">Survey Reports</span>
                        </Link>
                      </li>
                    </ul>
                  ) : null}
                </li>
                <li
                  className={`${
                    this.props.active === "settings" ? "active" : ""
                  } treeview`}
                >
                  <Link to={"/settings"}>
                    <i className="fas fa-cogs"></i>{" "}
                    <span style={{ marginLeft: "8px" }}>Settings</span>
                  </Link>
                </li>
              </ul>
            ) : user.role_id === 4 || user.role_id === 5 ? (
              <ul className="sidebar-menu">
                <li
                  className={`${
                    this.props.active === "dashboard" ? "active" : ""
                  } treeview`}
                >
                  <Link to={"/dashboard"}>
                    <i className="fa fa-dashboard"></i>{" "}
                    <span style={{ marginLeft: "10px" }}>Dashboard</span>
                  </Link>
                </li>
                {user.role_id === 4 ? (
                  <>
                    <li
                      className={`${
                        this.props.active === "classes" ? "active" : ""
                      } treeview`}
                    >
                      <Link to={"/classes"}>
                        <i className="fas fa-burn"></i>{" "}
                        <span style={{ marginLeft: "16px" }}>Classes</span>
                      </Link>
                    </li>
                    <li
                      className={`${
                        this.props.active === "coaches" ? "active" : ""
                      } treeview`}
                    >
                      <Link to={"/coaches"} key="coach">
                        <i className="fas fa-chalkboard-teacher"></i>{" "}
                        <span style={{ marginLeft: "10px" }}>Coaches</span>
                      </Link>
                    </li>
                  </>
                ) : null}
                <li
                  className={`${
                    this.props.active === "schedule" ? "active" : ""
                  } treeview`}
                >
                  <Link to={"/schedule"} key="schedule">
                    <i className="fa fa-calendar" aria-hidden="true"></i>{" "}
                    <span style={{ marginLeft: "8px" }}>Schedule</span>
                  </Link>
                </li>
                <li
                  className={`${
                    this.props.active === "all-bookings" ? "active" : ""
                  } treeview`}
                >
                  <Link to={"/all-bookings"}>
                    <i className="fa fa-ticket" aria-hidden="true"></i>{" "}
                    <span style={{ marginLeft: "8px" }}>Bookings</span>
                  </Link>
                </li>
                {user.role_id === 4 ? (
                  <>
                    <li
                      className={`${
                        this.props.active === "reviews-gyms" ? "active" : ""
                      } treeview`}
                    >
                      <Link to={"/reviews/gyms"}>
                        <i className="fa fa-star" aria-hidden="true"></i>{" "}
                        <span style={{ marginLeft: "8px" }}>Gym Reviews</span>
                      </Link>
                    </li>
                    <li
                      className={`${
                        this.props.active === "reviews-classes" ? "active" : ""
                      } treeview`}
                    >
                      <Link to={"/reviews/classes"}>
                        <i className="fa fa-star" aria-hidden="true"></i>{" "}
                        <span style={{ marginLeft: "8px" }}>Class Reviews</span>
                      </Link>
                    </li>
                    <li
                      className={`${
                        this.props.active === "reviews-coaches" ? "active" : ""
                      } treeview`}
                    >
                      <Link to={"/reviews/coaches"}>
                        <i className="fa fa-star" aria-hidden="true"></i>{" "}
                        <span style={{ marginLeft: "8px" }}>Coach Review</span>
                      </Link>
                    </li>

                    <li>
                      <a onClick={() => this.showAndHide()}>
                        <i className="fas fa-chart-bar"></i>
                        <span
                          style={{ marginLeft: "20px" }}
                          onClick={() => this.showAndHide()}
                        >
                          Reports
                        </span>
                        <i
                          className={`fa ${"fa-angle-left"} pull-right`}
                          style={{
                            transform: `rotate(${
                              this.state.showme ? -90 : 0
                            }deg)`,
                            transition: "0.4s",
                          }}
                        />
                      </a>
                      {this.state.showme ? (
                        <ul>
                          <li
                            className={`${
                              this.props.active === "reports-classes"
                                ? "active"
                                : ""
                            } treeview`}
                          >
                            <Link to={"/reports/classes"}>
                              <span className="linkbutton">Class Report</span>
                            </Link>
                          </li>
                          <li
                            className={`${
                              this.props.active === "reports-coaches"
                                ? "active"
                                : ""
                            } treeview`}
                          >
                            <Link to={"/reports/coaches"}>
                              <span className="linkbutton">Coach Report</span>
                            </Link>
                          </li>
                          <li
                            className={`${
                              this.props.active === "bookings-report"
                                ? "active"
                                : ""
                            } treeview`}
                          >
                            <Link to={"/reports/bookings-report"}>
                              <span className="linkbutton">
                                Bookings Report
                              </span>
                            </Link>
                          </li>
                        </ul>
                      ) : null}
                    </li>
                  </>
                ) : null}
              </ul>
            ) : null}
          </section>
          <div className="sidebar-version-main">
            <span className="sidebar-version">Version 2.1.19</span>
          </div>
        </aside>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(SideMenu);
