import { useDispatch, useSelector } from "react-redux";
import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { EntityViewLayout } from "../entityLayout/entityViewLayout";
import {
  cancelBookingByAdmin,
  getBookingsWithFilter,
} from "../../store/booking/bookingAction";
import moment from "moment";
import Modal from "../common/Modal";

export const ShowBookingTable = ({ title = "Bookings", isReport }) => {
  const book = useSelector((state) => state.book);
  const { isLodaing } = useSelector((state) => state.errors);
  const { user } = useSelector((state) => state.auth);
  const [showCancelConfirm, toggleCancelConfirm] = useState(false);
  const [selectedBookingToCancel, selectBookingToCancel] = useState();
  const [customFilters, setCustomFilters] = useState({});
  const role = user.roleId;
  const isViewerOrGym = role === 5 || role === 4;
  const isAdmin = role === 1;
  const dispatch = useDispatch();
  const todayDate = new Date();
  const afterTwoWeeksDate = new Date(
    new Date().setDate(todayDate.getDate() + 7)
  );

  const adminProps = isAdmin
    ? {
      isAdminCancelFilter: true,
      columns: [
        {
          Header: "Credits Return",
          accessor: "return_credits",
          paddingTop: "35px",
        },
        {
          Header: "Subscription Validity",
          accessor: "subscription_validity",
          paddingTop: "35px",
        },
        {
          Header: "User Current Credits",
          accessor: "user_current_credits",
          paddingTop: "35px",
        },
        {
          Header: "User Remaining Credits",
          accessor: "user_remaining_credits",
          paddingTop: "35px",
        },
        {
          Header: "Membership Tier",
          accessor: "membership_tier",
          paddingTop: "35px",
        },
      ],
    }
    : { columns: [] };
  const gymOrViewerProps = isViewerOrGym
    ? { isCanceledFilter: true, columns: [] }
    : { columns: [] };
  const defaultDateRange = isReport
    ? {}
    : { start_date: todayDate, end_date: afterTwoWeeksDate };
  const tableProps = {
    name: title,
    routePath: "/all-bookings",
    isBreadcrumbs: true,
    isAddNew: false,
    isSearch: true,
    isFilters: true,
    isDateFilters: true,
    isPriceFilter: true,
    isCsvConvert: true,
    isCanceledFilter: true,
    defaultDateRange,
    ...adminProps,
    ...gymOrViewerProps,
    columns: [
      ...(isAdmin
        ? [
          {
            Header: "Booking ID",
            accessor: "id",
            paddingTop: "35px",
          },
        ]
        : []),
      {
        Header: "Booking Date",
        accessor: "createdAt",
        paddingTop: "35px",
        maxWidth: "150px",
        Cell: function getBookingDate(row) {
          return <div>{row.original.createdAt}</div>;
        },
      },
      {
        Header: "Gym Name",
        accessor: "gym_name",
        paddingTop: "35px",
      },
      {
        Header: "Class Date",
        accessor: "class_date",
        paddingTop: "35px",
      },
      {
        Header: "Class Time",
        accessor: "class_start_time",
        width: 100,
        paddingTop: "35px",
      },
      {
        Header: "Class Name",
        accessor: "class_name",
        paddingTop: "35px",
      },
      {
        Header: "User Name",
        accessor: "user_name",
        paddingTop: "35px",
      },
      {
        Header: "User ID",
        accessor: "user_id",
        width: 80,

        paddingTop: "35px",
      },
      {
        Header: "Price",
        accessor: "price",
        width: 80,
        paddingTop: "35px",
      },
      {
        Header: "Is Cancelled",
        accessor: "is_cancel",
        paddingTop: "35px",
        Cell: function getCancelDate(row) {
          return <div>{`${row.original.is_cancel}`}</div>;
        },
      },
      {
        Header: "Cancellation Date(UTC)",
        accessor: "cancelation_date",
        paddingTop: "35px",
        width: 200,
        Cell: function getCancelDate(row) {
          return (
            <div>
              {row.original.cancelation_date
                ? moment(row.original.cancelation_date).format(
                  "YYYY-MM-DD HH:mm:s"
                )
                : ""}
            </div>
          );
        },
      },
      ...adminProps.columns,
      {
        Header: "Actions",
        headerStyle: {
          height: 45,
          paddingTop: 15,
          fontWeight: "bold",
        },
        Cell: function renderCancelButton(props) {
          return isViewerOrGym ||
            props.original.is_cancel ||
            props.original.is_admin_cancel ? null : (
            <Fragment>
              <button
                type="button"
                className="btn btn-danger"
                data-toggle="modal"
                data-target="#modal-default"
                title="Delete"
                style={{ marginLeft: "10px" }}
                onClick={() => {
                  handleCancelBooking(props.original.id);
                  selectBookingToCancel(props.original.id);
                }}
              >
                Cancel
              </button>
            </Fragment>
          );
        },
      },
    ],
  };

  const csvHeaders = [
    ...(isAdmin ? [{ label: "Booking ID", key: "id" }] : []),
    {
      label: "Booking Date",
      key: "createdAt",
    },
    {
      label: "Gym Name",
      key: "gym_name",
    },
    {
      label: "Class Date",
      key: "class_date",
    },
    {
      label: "Class Time",
      key: "class_start_time",
    },
    {
      label: "Cancellation Date",
      key: "cancelation_date",
    },
    {
      label: "Class Name",
      key: "class_name",
    },
    {
      label: "User Name",
      key: "user_name",
    },
    {
      label: "User ID",
      key: "user_id",
    },
    {
      label: "Price",
      key: "price",
    },
    {
      label: "Is Cancelled",
      key: "is_cancel",
    },
    ...(isAdmin
      ? [
        {
          label: "Percentage Charged",
          key: "percentage",
        },
        {
          label: "Credits Return",
          key: "return_credits",
        },
        {
          label: "subscription_validity",
          key: "subscription_validity",
        },
        {
          label: "user_current_credits",
          key: "user_current_credits",
        },
        {
          label: "user_remaining_credits",
          key: "user_remaining_credits",
        },
        {
          label: "membership_tier",
          key: "membership_tier",
        },
      ]
      : []),
  ];

  const searchFields = ["is_cancel", "cancelation_date"];
  let history = useHistory();

  const handleCancelBooking = () => {
    toggleCancelConfirm(true);
  };

  const customFilter = ({
    start_date,
    end_date,
    start_time,
    end_time,
    price,
    is_admin_cancel,
    is_cancel,
  }) => {
    setCustomFilters({
      start_date,
      end_date,
      start_time,
      end_time,
      price,
      is_admin_cancel,
      is_cancel,
    });

    dispatch(
      getBookingsWithFilter({
        start_date,
        end_date,
        start_time,
        end_time,
        price,
        is_admin_cancel,
        is_cancel,
      })
    );
  };

  const clearFilter = () => {
    dispatch(getBookingsWithFilter({ ...defaultDateRange, is_cancel: "0" }));
  };

  useEffect(() => {
    dispatch(
      getBookingsWithFilter({
        ...defaultDateRange,
        is_cancel: "0",
        ...customFilters,
      })
    );
  }, []);

  return (
    <>
      {showCancelConfirm && (
        <Modal
          id="cancelBooking"
          key="cancelBooking"
          open={true}
          size={"format"}
          header="Cancel Booking"
          onClose={() => {
            toggleCancelConfirm(false);
          }}
        >
          {`This will cancel the user’s booking. Are you sure you want to
          continue?`}
          <div
            className="row"
            style={{
              marginTop: "20px",
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <button
              type="button"
              className="btn btn-danger"
              style={{ marginLeft: "10px" }}
              onClick={() => {
                dispatch(
                  cancelBookingByAdmin(selectedBookingToCancel, {
                    ...defaultDateRange,
                    ...customFilters,
                  })
                );

                toggleCancelConfirm(false);
              }}
            >
              Yes, cancel.
            </button>
            <button
              type="button"
              className="btn"
              style={{ marginLeft: "10px" }}
              onClick={() => {
                toggleCancelConfirm(false);
              }}
            >
              No
            </button>
          </div>
        </Modal>
      )}
      <EntityViewLayout
        isLoading={isLodaing}
        items={book.bookings}
        searchFields={searchFields}
        tableProps={tableProps}
        history={history}
        csvHeaders={csvHeaders}
        customFilter={customFilter}
        clearFilter={clearFilter}
        csvFilePrefix={isReport ? "bookings-report" : "booking"}
      />
    </>
  );
};
